import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { store } from "../../../shared";
import { setOrder } from "../../../shared/slice/order/OrderSlice";
import styled from "styled-components";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getSymbolFromCurrency from "currency-symbol-map";
import { useTranslation } from "react-i18next";
import { setScroll } from "../../../shared/slice/scroll/ScrollSlice";
import { setEditOrder, setOpenModal, setProduct } from "../../../shared/slice/restaurant/RestaurantSlice";
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
function SubmitOrderconfirmed({
  product,
  selectedSize,
  setSelectedSize,
  objectOptions,
  setObjectOptions,
  counter,
  checkedOption,
  checkedIndices,
  number,
}) {
  const { id } = useParams();

  const { t } = useTranslation();
  const scroll = useSelector((state) => state.scroll.scroll);
  const editOrder = useSelector((state) => state.restaurant.editOrder);
  const openModal = useSelector((state) => state.restaurant.openModal);
  const products = useSelector((state) => state.restaurant.product)
  const order = useSelector((state) => state.order.order);
  const currency = useSelector((state) => state.restaurant.menu.currency);
  const frais = useSelector((state) => state.restaurant.frais);
  const taux = useSelector((state) => state.restaurant.taux);
  const apply = useSelector((state) => state.restaurant.apply);
  const dispatch = useDispatch();
  const modeId = useSelector((state) => state.restaurant.modeId);
  const [priceOf1unite, setPriceOf1unite] = useState(() =>
    apply === "product"
      ? frais !== null
        ? frais
        : selectedSize.price * (taux / 100)
      : 0
  );

  const [quantity, setQuantity] = useState(()=>editOrder.open?editOrder.order.quantity:1); // quantité d'ordre
  const [priceHt, setPriceHt] = useState(1); // prix ht des options

  const groupName = selectedSize.optionGroupe;
  const subOption = selectedSize.subOptionSelected;
console.log("all product",products);

{ /*----encryption / decryption functions----*/ }
const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), "secret_key").toString();
};
const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, "secret_key");
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8);

    // Check if the decrypted text is valid JSON
    if (!decryptedText) {
      throw new Error("Decryption failed or returned empty string.");
    }

    return JSON.parse(decryptedText);
  } catch (error) {
    console.error("Error during decryption or parsing:", error);
    return null; // or handle the error as appropriate
  }
};
  //-----------------calcul de prix de produit-------------//
  useEffect(() => {
    let p = 0;
    let pHt = 0;
    for (let i = 0; i < objectOptions.length; i++) {
      let priceoptions = 0;
      let priceoptionsHt = 0;
      if (objectOptions[i].options.length > 0) {
        for (let j = 0; j < objectOptions[i].options.length; j++) {
          priceoptions += objectOptions[i].options[j].price;
          priceoptionsHt += objectOptions[i].options[j].priceHt;
        }
      }
      const price =
        (objectOptions[i].price + priceoptions) * objectOptions[i].quantity;
      const priceHt =
        (objectOptions[i].priceHt + priceoptionsHt) * objectOptions[i].quantity;
      if (price) {
        p += price;
        pHt += priceHt;
        console.log(priceHt);
      }
    }
    setPriceOf1unite(
     ( (apply === "product"
        ? frais !== null
          ? frais
          : selectedSize.price * (taux / 100)
        : 0) +
      (selectedSize.price + p)) * quantity
    );

    
    setPriceHt((selectedSize.priceHt + pHt) * quantity);
    
  }, [selectedSize, objectOptions, number, quantity, checkedIndices.length]);
  
  {/*---------------------Tax calculation --------------------- */}
  let taxesId = {} // tax accumulation per tax Id 
// if (objectOptions){  
  const taxElement = objectOptions
    .map((item) => {
      const itemTaxElement = [{ [item?.tax]: item.taxPrice?.toFixed(2) }];
     
      if (taxesId[item.taxId]) {
        taxesId[item.taxId] += parseFloat(item.taxPrice.toFixed(2));
      } else {
        taxesId[item?.taxId] = parseFloat(item.taxPrice?.toFixed(2));
      }
    
      const optionTaxElement = item.options.map((optionTax) => {

        if (taxesId[optionTax?.taxId]) {
          taxesId[optionTax.taxId] += parseFloat(optionTax.taxPrice?.toFixed(2));
        } else {
          taxesId[optionTax.taxId] = parseFloat(optionTax.taxPrice?.toFixed(2));
        }
      
        return { [optionTax.tax]: optionTax.taxPrice?.toFixed(2) };
      });
      return itemTaxElement.concat(optionTaxElement);
    })
    .flat();
  
  const TaxCalculator = (taxElement) => {
    // Create an object to store the total for each unique tax rate
    const taxTotals = taxElement.reduce((acc, tax) => {
      const taxRate = Object.keys(tax)[0];
      const taxAmount = Object.values(tax)[0];


      if (acc[taxRate]) {
        acc[taxRate] += parseFloat(taxAmount);
      } else {
        acc[taxRate] = parseFloat(taxAmount);
      }
      return acc;
    }, {});

    if (taxTotals[selectedSize.tax]) {
      taxTotals[selectedSize.tax] += selectedSize.price - selectedSize.priceHt;
    } else {
      taxTotals[selectedSize.tax] = selectedSize.price - selectedSize.priceHt;
    }
    if (taxesId[selectedSize.taxId]) {
      taxesId[selectedSize.taxId] += selectedSize.price - selectedSize.priceHt;
    } else {
      taxesId[selectedSize.taxId] = selectedSize.price - selectedSize.priceHt;
    }
    return taxTotals;
  };
  
  //submit the ordre
  const handleSubmit = () => {
    console.log("order",order);
    const cryptedOrder = localStorage.getItem("OrderStoreWithID" + id);
    let allOrders = []
   if(cryptedOrder){
      allOrders = decryptData(cryptedOrder)
    } 
    let newOrderList =[]
    if (order){
       newOrderList = JSON.parse(JSON.stringify(order));
      if (editOrder.open){
        const editedOrder= newOrderList.find((order)=>order.orderKey == editOrder.order.orderKey)
        console.log("editedOrder",editedOrder);
        if (editedOrder) {
          if (product.optionGroups.length > 0){          
            localStorage.removeItem(`QuantityInCart_ForStoreWithId${id}_ForProductWith${editOrder.order.product.id}`);
          newOrderList.splice(newOrderList.indexOf(editedOrder), 1);
          if(allOrders.length >0){
            allOrders = allOrders.filter(order => order.orderKey !== editedOrder.orderKey)
          }
          console.log("newOrderList2",newOrderList,allOrders);
        }
      }else{
        console.log("order not found to delete");
      }
    }}
    // Check if the button should be enabled
    let optionGroupe = {};
    let subOptionGroup = {};
    const isMainOptionsValid = groupName.every((optionGroup, sectionIndex) => {
      const totalQuantityInSection = Object.keys(counter).reduce(
        (total, key) => {
          if (key.startsWith(`${sectionIndex}-`)) {
            total += counter[key];
          }
          return total;
        },
        0
      );
      const sectionCheckedIndices = checkedIndices.filter((index) =>
        index.startsWith(`${sectionIndex}`)
      );
      const forceMin = optionGroup.force_min;
      if (forceMin > 0) {
        if (
          (forceMin <= sectionCheckedIndices.length ||
            forceMin <= totalQuantityInSection) === false
        ) {
          optionGroupe = {
            id: optionGroup._id,
            min: forceMin,
            name: optionGroup.name,
          };
        }
        return (
          forceMin <= sectionCheckedIndices.length ||
          forceMin <= totalQuantityInSection
        );
      } else {
        return true;
      }
    });
    const isSubOptionsValid = subOption.every((optionGroup, sectionIndex) => {
      const sectionCheckedOption = checkedOption.filter((index) =>
        index.includes(optionGroup.key)
      );
      if (optionGroup.required === true) {
        const forceMin = optionGroup.forceMin;
        if (!(forceMin <= sectionCheckedOption.length)) {
          subOptionGroup = {
            id: optionGroup.key,
            min: forceMin,
            name: optionGroup.option,
          };
        }
        return forceMin <= sectionCheckedOption.length;
      } else {
        return true;
      }
    });
    if (isMainOptionsValid && isSubOptionsValid) {
      // Handle the submission of the order
      const taxTotals = TaxCalculator(taxElement); // tax accumulation per tax rate
      if (editOrder.open) {
        const sortedOptions = [...objectOptions];
        sortedOptions.sort((a, b) => a.sectionIndex - b.sectionIndex);
        objectOptions.length = 0;
        objectOptions.push(...sortedOptions)
        console.log("sorting",sortedOptions,objectOptions);
      } else {
        objectOptions.sort((a, b) => a.sectionIndex - b.sectionIndex);
      }
    console.log("objectOptions",objectOptions);
    const generateRandomKey = (length = 10) => {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = 'order';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    };
      const newOrder = {
        product: {
          id: product._id,
          image: product.image,
          name: product.name,
          description: product.description,
          price: selectedSize.price,
          priceHt: selectedSize.priceHt,
          tax: selectedSize.tax,
          taxId: selectedSize.taxId,
          size: selectedSize.size,
            },
        options: objectOptions,
        quantity: quantity,
        price: priceOf1unite,
        unitePrice: priceOf1unite/quantity,
        priceHt: priceHt,
        unitePriceHt:priceHt/quantity,
        taxes: taxTotals,
        taxesId: taxesId,
        note: selectedSize.note,
        orderKey : generateRandomKey(),
        mode:modeId,
      };
const updatedProducts = products.map((pro) => {
  if (pro._id === product._id) {
    
    let QuantityInCart = localStorage.getItem(`QuantityInCart_ForStoreWithId${id}_ForProductWith${pro._id}`) ? parseInt(localStorage.getItem(`QuantityInCart_ForStoreWithId${id}_ForProductWith${pro._id}`)) : 0;
    if (product.optionGroups.length == 0){
      QuantityInCart = quantity;
    } else {
      QuantityInCart += newOrder.quantity;
    }
   
    localStorage.setItem(`QuantityInCart_ForStoreWithId${id}_ForProductWith${pro._id}`, QuantityInCart);
    return { ...pro, QuantityInCart };
  }
  return pro;
});
console.log("Updated products:", updatedProducts);
dispatch(setProduct({ product: updatedProducts }));

   {/* ----------------- check if order already existe to accumulate and dispatch order ---------------*/}

    //   if (product.optionGroups.length == 0){
    //       const findOrder = order.findIndex((matchOrder)=>matchOrder.product.id == product.id)
    //     if (findOrder > -1){
    //       console.log("findOrder",findOrder);
    //       order[findOrder].quantity += quantity
    //       store.dispatch(setOrder({ order: [...newOrderList] }));
    //       const st = [...allOrders]
    //       console.log("st",st,allOrders);
    //       const encryptedOrder = encryptData(st);
    //       localStorage.setItem(`OrderStoreWithID${id}`, encryptedOrder);
    //       console.log("order after submit",order);
    //     } else {
    //       store.dispatch(setOrder({ order: [...newOrderList, newOrder] }));
    //       const st = [...allOrders, newOrder]
    //       console.log("st",st,allOrders);
    //       const encryptedOrder = encryptData(st);
    //       localStorage.setItem(`OrderStoreWithID${id}`, encryptedOrder);
    //       console.log("order after submit",order);
    //     }
    //   }else {    
    //   store.dispatch(setOrder({ order: [...newOrderList, newOrder] }));
    //   const st = [...allOrders, newOrder]
    //   console.log("st",st,allOrders);
    //   const encryptedOrder = encryptData(st);
    //   localStorage.setItem(`OrderStoreWithID${id}`, encryptedOrder);
    //   console.log("order after submit",order);
    // }
      
    if(order.length == 0){
      store.dispatch(setOrder({ order: [...newOrderList, newOrder] }));
          const st = [...allOrders, newOrder]
          console.log("st",st,allOrders);
          const encryptedOrder = encryptData(st);
          localStorage.setItem(`OrderStoreWithID${id}`, encryptedOrder);
    }else {
        if (product.optionGroups.length == 0){
          const findOrder = order.findIndex((matchOrder)=>matchOrder.product.id == product._id)
          console.log("findOrder",findOrder);
          // const updateOrder = order
          if (findOrder > -1){
          console.log("findOrder",findOrder);
          console.log("newOrderList[findOrder]",newOrderList[findOrder]);
          
          newOrderList[findOrder].quantity = quantity
          store.dispatch(setOrder({ order: [...newOrderList] }));
          const st = [...allOrders]
          console.log("st",st,allOrders);
          const encryptedOrder = encryptData(st);
          localStorage.setItem(`OrderStoreWithID${id}`, encryptedOrder);
        } else {
          store.dispatch(setOrder({ order: [...newOrderList, newOrder] }));
          const st = [...allOrders, newOrder]
          console.log("st",st,allOrders);
          const encryptedOrder = encryptData(st);
          localStorage.setItem(`OrderStoreWithID${id}`, encryptedOrder);
          console.log("findOrder == -1");
          
        }
      }else {    
        console.log("product has optionGroups");
      store.dispatch(setOrder({ order: [...newOrderList, newOrder] }));
      const st = [...allOrders, newOrder]
      console.log("st",st,allOrders);
      const encryptedOrder = encryptData(st);
      localStorage.setItem(`OrderStoreWithID${id}`, encryptedOrder);
    }
  }
  console.log("order after submit",order);
    
    // Reset states and close modals
      store.dispatch(setOpenModal({
        product: undefined,
        open: false,
      }));
      setSelectedSize({});
      setPriceOf1unite(() =>
        apply === "product"
          ? frais !== null
            ? frais
            : selectedSize.price * (taux / 100)
          : 0
      );
      // setPriceOf1unite(0);
      store.dispatch(setScroll({ scroll: 0}));
      store.dispatch(setEditOrder(
        {
           open: false,
           objectOptions:[],
           checkedIndices: [],
           checkedOptions: [],
           counter:{},
           orderQuantity:0,
           note:undefined,
       }
       ));
    } else {
      // If either main or sub-options are not valid, handle accordingly
      if (!isMainOptionsValid) {
        const invalidMainOption = document.getElementById(
          `mainOption-${optionGroupe.id}`
        );
        if (invalidMainOption) {
          invalidMainOption.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start",
          });
          invalidMainOption.style.border = "2px solid #F36767";
          setTimeout(() => {
            invalidMainOption.style.border = "2px solid #fff";
          }, 3000);
          toast.dismiss();
          toast.error(
            `${t('select at least')} ${optionGroupe.min} ${t('of')} ${optionGroupe.name}`,
            {
              position: "top-right",
              autoClose: 1500,
              pauseOnHover: false,
              transition: Slide,
            }
          );
        }
      } else if (!isSubOptionsValid) {
        const invalidSubOption = document.getElementById(
          `subOption-${subOptionGroup.id}`
        );
        if (invalidSubOption) {
          invalidSubOption.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          });
          invalidSubOption.style.border = "2px solid #F36767";
          invalidSubOption.style.padding = "10px";
          setTimeout(() => {
            invalidSubOption.style.border = "none";
            invalidSubOption.style.padding = "none";
          }, 3000);
          toast.dismiss();
          toast.error(
            `${t('select at least')} ${subOptionGroup.min} ${t('of')} ${subOptionGroup.name}`,
            {
              position: "top-center",
              autoClose: 1500,
              pauseOnHover: false,
              transition: Slide,
            }
          );
        }
      }
    }
  };

  
  const handleDown = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  // button plus de quantité
  const handleUp = () => {
    setQuantity(quantity + 1);
  };
  return (
    <div>
      <BorderTop>
        <div className="quantity-container">

          <button className="button-minus" onClick={handleDown}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              className="styles__StyledInlineSvg-sc-12l8vvi-0 jFpckg"
            >
              <path
                d="M8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H16C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11H8Z"
                fill="currentColor"
              ></path>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
                fill="currentColor"
              ></path>
            </svg>
          </button>
          <div>{quantity}</div>
          <button className="button-plus" onClick={handleUp}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              className="styles__StyledInlineSvg-sc-12l8vvi-0 jFpckg"
            >
              <path
                d="M12 7C12.5523 7 13 7.44772 13 8V11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H13V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V13H8C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11H11V8C11 7.44772 11.4477 7 12 7Z"
                fill="currentColor"
              ></path>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                fill="currentColor"
              ></path>
            </svg>
          </button>
          <>
          <button
              type="submit"
              className="btn-add-order"
              onClick={handleSubmit}
              // disabled={!isButtonEnabled}
            >
              {editOrder.open
                ? `${t("change your order")} ${getSymbolFromCurrency(currency)}`
                : `${t("Add to cart")} ${getSymbolFromCurrency(currency)}`}
              {parseFloat(priceOf1unite).toFixed(2)}
            </button>
            <ToastContainer
              position="top-center"
              autoClose={2000}
              pauseOnHover={false}
            // transition= {Slide},
            />
          </>
        </div>
      </BorderTop>
    </div>
  );
}
const BorderTop = styled.div`
  border-top: 1px solid #fff;
`;
export default SubmitOrderconfirmed;
