import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import { store } from "../../shared";
import {
  setEmail,
  setModalSendEmail,
  setModalSignup,
} from "../../shared/slice/ModalLogin/ModalLoginSlice";
import { sendEmail, signup, UserExistOrNot } from "../../shared/slice/auth/AuthService";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import styled from "styled-components";
import styles from "../../utils/checkPwdSignUp/checklist.module.css";
import { eatorder } from "../../assets/images/exports";
import { setScroll } from "../../shared/slice/scroll/ScrollSlice";
import { useTranslation } from "react-i18next";
import CheckList from "../../utils/checkPwdSignUp/CheckPwd";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ReportIcon from '@mui/icons-material/Report';
import {PASSWORD_VALID_REGEX} from  '../../utils/checkPwdSignUp/validationRules'
import { toast } from "react-toastify";
export default function ModalSignup() {
  const { t } = useTranslation();

  const modalSignup = useSelector((state) => state.modalLogin.modalSignup);
  const email = useSelector((state) => state.modalLogin.email);
  const [firstName, setFirstName] = useState({
    firstName :"",
    firstNameErr:false, 
  });
  const [lastName, setLastName] = useState({
    lastName :"",
    lastNameErr:false, 
  });
//pw reveal
const [passwordShown, setPasswordShown] = useState(false);

const togglePasswordVisibility = () => {
  setPasswordShown(!passwordShown);
};

  // const [email, setEmail] = useState("");
  const [password, setPassword] = useState({
    pwd: "",
    pwdError: false,
    pwdchart:false,
  });
  const [confirmPassword, setConfirmPassword] = useState({
    confirmpwd:"",
    confirmpwdErr:false,
  });
  const [phoneNumber, setPhoneNumber] = useState({
    phoneNumber:"",
    phoneNumberErr:false,
  });
  const [sexe, setSexe] = useState("homme");
  const [emailErr, setEmailErr] = useState(false);

  const scroll = useSelector((state) => state.scroll.scroll);

  useEffect(() => {
    if (modalSignup) {
      store.dispatch(setScroll({ scroll: 1}));
      
    }
  }, [modalSignup]);
  useEffect(() => {
    setFirstName({
      firstName :"",
      firstNameErr:false, 
    });
 setLastName({
      lastName :"",
      lastNameErr:false, 
    });
  
    setPassword({
      pwd: "",
      pwdError: false,
      pwdchart:false,
    });
setConfirmPassword({
      confirmpwd:"",
      confirmpwdErr:false,
    });
    setPhoneNumber({
      phoneNumber:"",
      phoneNumberErr:false,
    });
  }, []);
  //regex pattern
  const validEmail = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const validPassword = PASSWORD_VALID_REGEX;

  // closa Sign up modal
  const handleCloseSignup = () => {
    store.dispatch(setModalSignup({ modalSignup: false }));
    store.dispatch(setScroll({ scroll: 0 }));
    setFirstName({
      firstName :"",
      firstNameErr:false, 
    });
 setLastName({
      lastName :"",
      lastNameErr:false, 
    });
  
    setPassword({
      pwd: "",
      pwdError: false,
      pwdchart:false,
    });
setConfirmPassword({
      confirmpwd:"",
      confirmpwdErr:false,
    });
    setPhoneNumber({
      phoneNumber:"",
      phoneNumberErr:false,
    });
  };

  const handleFirstNameChange = (e) => {
    
    setFirstName({
      firstName : e.target.value,
      firstNameErr:false,
    });
  };

  const handleEmailChange = (e) => {
    store.dispatch(setEmail({ email: e.target.value }));
  };

  const handleLastNameChange = (e) => {
    setLastName({
      lastName :e.target.value,
      lastNameErr:false, 
    });
  };

  const handlePasswordChange = (e) => {
    setPassword({
      ...password,
      pwd: e.target.value,
      pwdError: false,
      
    });
  };

  const handlePConfirmPasswordChange = (e) => {
    setConfirmPassword({
      confirmpwd: e.target.value,
      confirmpwdErr:false,
    });
    };
useEffect(() => {
  console.log();
  if(confirmPassword!== "")
  {if(confirmPassword.confirmpwd.length!==0)
 { if (password.pwd ==confirmPassword.confirmpwd){
    console.log(password.pwd,"//",confirmPassword.confirmpwd);
   const pass = document.getElementById("confirmpassword")
  if(pass!==null){ pass.style.borderColor= "green"}

  } else {
    const error = document.getElementById("confirmpassword")
    if(error!==null) {error.style.borderColor= "red"}
  }}}
}, [password.pwd,confirmPassword.confirmpwd])
  const handlePhoneNumberChange = (value, event) => {
    setPhoneNumber({
      phoneNumber:value,
      phoneNumberErr:false,
    });;
  };

  const handleSexeChange = (e) => {
    setSexe(e.target.value);
  };

  const handleSignup = async () => {
    //first and last name  are required fields 
    if (firstName.firstName.length == 0) {
      firstName.firstNameErr=true;
      const fName = document.getElementById( "firstName" );
      fName.style.border = "1px solid #F36767";
    } 
     if (lastName.lastName.length == 0) {
      lastName.lastNameErr=true;
      const lName = document.getElementById( "lastName" );
      lName.style.border = "1px solid #F36767";
    }
    // Mail adresse 
    if (!validEmail.test(email)) {
          setEmailErr(true);
       }
    if(phoneNumber.phoneNumber.length==0){
      setPhoneNumber({
        ...phoneNumber,
        phoneNumberErr:true,
      });
    }
    if ( !validPassword.test(password.pwd)) {
      setPassword({
        ...password,
        pwdError: true,
      });
      return;
    }

    if (password.pwd !== confirmPassword.confirmpwd) {
      setConfirmPassword({
        ...confirmPassword,
        confirmpwdErr:true,
      });
      return;
    }
    const newUser = {
      firstName: firstName.firstName,
      lastName: lastName.lastName,
      email: email,
      password: password.pwd,
      phoneNumber: phoneNumber.phoneNumber,
      sexe: sexe,
    };

    try {

      
      const  UserExist= UserExistOrNot(email);
      if (UserExist.msg==="User Exist"){
        toast.error("User already exists with this email.")
      }else{
      const response = await signup(newUser);
        // Assuming sendEmail is an asynchronous function that returns a response
        const emailResponse = await sendEmail(response.user._id);
        console.log("emailResponse",emailResponse)
        if (emailResponse) {
          setFirstName("");
          setLastName("");
          setPassword("");
          setConfirmPassword("");
          setPhoneNumber("");
          setSexe("");
          store.dispatch(setModalSignup({ modalSignup: false }));
          store.dispatch(setModalSendEmail({ modalSendEmail: true }));
          store.dispatch(setScroll({ scroll:0 }));

          // setShowSignup(false);
          // setShowSendEmail(true);
        } else {
          console.error("Error sending verification email");
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
//  const classNameConfirPwd = ()=>{
//   if(confirmPassword.confirmpwd){
//     if (password.pwd ==confirmPassword.confirmpwd){
//       console.log(styles.passe);
//       return styles.passed
//     } else {
//       return styles.error
//     }
//   }
//  }
const closeSignupModal = () => {
  store.dispatch(setModalSignup({ modalSignup: false }));
  store.dispatch(setScroll({ scroll:0 }));
  setFirstName("");
  setLastName("");
  setPassword("");
  setConfirmPassword("");
  setPhoneNumber("");
  setSexe("");
 
};
  return (
    <StyledModalSignup isOpen={modalSignup} onRequestClose={handleCloseSignup}>
      <CloseButton onClick={closeSignupModal}></CloseButton>
      <img
        src={eatorder}
        style={{
          width: "150px",
          height: "50px",
          objectFit: "contain",
          marginLeft: "29%",
        }}
      ></img>

      <h3 className="mb-5 mt-3">{t("Sign up")}</h3>
      <div style={{marginLeft:"18px",display:"flex",alignItems:"center",fontSize:"14px",fontFamily:"QuickSandLight"}}>
      <ReportIcon style={{color:"red",fontSize:"18px"}}/>{" "}
        <p style={{color:"red",textAlign:"left",}}> {t("All fields are required")}</p>
        </div>
      <div style={{ display: "flex", width: "90%", marginLeft: "5%" }}>
        
        <Form.Control
          type="text"
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            width: "100%",
            borderRadius: "25px",
          }}
          placeholder={t("First Name")}
          id="firstName"
          value={firstName.firstName}
          onChange={handleFirstNameChange}
          onClick={()=>{
            const fName = document.getElementById( "firstName" );
          fName.style.border = "1px solid #dee2e6";
        }}
          onKeyDown={(e) => e.stopPropagation()}
        />
        <Form.Control
          type="text"
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            marginLeft: "5%",
            width: "100%",
            borderRadius: "25px",
          }}
          placeholder={t("Last Name")}
          id="lastName"
          value={lastName.lastName}
          onChange={handleLastNameChange}
          onClick={()=>{
            const lName = document.getElementById( "lastName" );
          lName.style.border = "1px solid #dee2e6";
          }}
          onKeyDown={(e) => {e.stopPropagation()
         }}
          />
      </div>
      <div style={{display:"flex",flexDirection:"column",marginLeft:"20px"}}>
      {firstName.firstNameErr && <span    className="error-message"
          style={{ color: "red", textAlign: "left", fontFamily:"QuickSandLight",fontSize:"14px"}}
      ><ErrorOutlineIcon style={{fontSize:"15px"}}/> {t("First name cannot be empty !")}</span>}
      {lastName.lastNameErr && <span    className="error-message"
          style={{ color: "red", textAlign: "left", fontFamily:"QuickSandLight",fontSize:"14px"}}
      ><ErrorOutlineIcon style={{fontSize:"15px"}}/> {t("Last name cannot be empty !")}</span>}
      </div>
      <Form.Control
        type="email"
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          marginLeft: "5%",
          width: "90%",
          borderRadius: "25px",
        }}
        placeholder={t("Email Address")}
        id="email"
        value={email}
        onChange={handleEmailChange}
        onClick={()=>setEmailErr(false)}
        onKeyDown={(e) => e.stopPropagation()}
      />
      {emailErr && (
        <span
          className="error-message"
          style={{ color: "red", textAlign: "left", 
          fontFamily: "QuickSandLight, sans-serif", 
          marginLeft:"20px",fontSize:"14px",float:"left",paddingBottom:"5px"}}
          >
       <ErrorOutlineIcon style={{fontSize:"17px"}}/> Not a valid email !
        </span>
      )}
      <PhoneInputNumber
        defaultCountry="FR"
        className="phone-input"
        placeholder={t("Enter Phone Number")}
        value={phoneNumber.phoneNumber}
        onChange={handlePhoneNumberChange}
        onClick={()=>setPhoneNumber({
          ...phoneNumber,
          phoneNumberErr:false,
        })}
        onKeyUp={(e) => e.stopPropagation()}
      />
      {phoneNumber.phoneNumberErr && (
        <span
          className="error-message"
          style={{ color: "red", textAlign: "left", 
          fontFamily: "QuickSandLight, sans-serif", 
          marginLeft:"20px",fontSize:"14px",float:"left",paddingBottom:"5px"}}
          >
       <ErrorOutlineIcon style={{fontSize:"17px"}}/> phone number cannot be empty !
        </span>
        )}
      <div
        style={{
          display: "flex",
          width: "90%",
          marginLeft: "5%",
          flexDirection: "column",
        }}
      >
         <div style={{ position: 'relative', marginTop: '10px', marginBottom: '10px', width: '100%' }}>
            <Form.Control
                type={passwordShown ? 'text' : 'password'}
                // className={`form-control ${passwordError && "is-invalid"}`}
                style={{ paddingRight: '30px', borderRadius: '25px' }}
          placeholder={t("Password")}
          id="password"
          value={password.pwd}
          onChange={handlePasswordChange}
          onClick={()=>setPassword({
            ...password,
            pwdchart:true,
          })}
          onKeyDown={e => e.stopPropagation()}
        />
                  <span
        onClick={togglePasswordVisibility}
        style={{
          position: 'absolute',
          right: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: '2',
        }}
      >
        {passwordShown ? '🙈' : '👁️'}
      </span>
      </div>



          { password.pwdchart &&  <div style={{display:"flex",justifyContent:"flex-start",marginLeft:"10px"}}>
        <div style={{width:"max-content"}}>
         <CheckList value={password} />
         </div>
         </div>}
        {password.pwdError && (
        <PasswordMessage>
       <ErrorMessageIcon style={{fontSize:"19px"}}/> Not a valid password !
        </PasswordMessage >
      )}
   <div style={{ position: 'relative', marginTop: '10px', marginBottom: '10px', width: '100%' }}>
            <Form.Control
                type={passwordShown ? 'text' : 'password'}
                // className={`form-control ${passwordError && "is-invalid"}`}
                style={{ paddingRight: '30px', borderRadius: '25px' }}
          placeholder={t("Confirm Password")}
          id="confirmpassword"
          value={confirmPassword.confirmpwd}
          onChange={handlePConfirmPasswordChange}
          onClick={()=>{setConfirmPassword({
            ...confirmPassword,
            confirmpwdErr:false,
          })
        console.log(confirmPassword);
        }}
          onKeyDown={(e) => e.stopPropagation()}
        />
                          <span
        onClick={togglePasswordVisibility}
        style={{
          position: 'absolute',
          right: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: '2',
        }}
      >
        {passwordShown ? '🙈' : '👁️'}
      </span>
      </div>
        {confirmPassword.confirmpwdErr && (
          <div className="error-message" style={{color:"red",fontFamily:"QuickSandLight",fontSize:""}} >
            <ErrorMessageIcon style={{fontSize:"19px"}}/> {t("Passwords do not match")}
          </div>
        )}
      </div>

      <Form.Control
        as="select"
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          marginLeft: "5%",
          width: "90%",
          borderRadius: "25px",
        }}
        id="sexe"
        value={sexe}
        onChange={handleSexeChange}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <option value="homme"> {t("Male")}</option>
        <option value="femme"> {t("Female")}</option>
      </Form.Control>
      
      <SignupButton onClick={handleSignup}>{t("Signup")}</SignupButton>
    </StyledModalSignup>
  );
}

function ReactModalAdapter({ className, modalClassName, ...props }) {
  return (
    <Modal className={modalClassName} portalClassName={className} {...props} />
  );
}

const StyledModalSignup = styled(ReactModalAdapter).attrs({
  modalClassName: "ModalSignup",
  overlayClassName: "OverlaySignup",
})`
  .ModalSignup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 10px;
    border-radius: 15px;
    min-height: 300px;
    width: 400px;
    text-align: center;
  }
  @media (max-width: 768px) {
    .Modal {
      width: 90%;
      min-width: 90%;
    }
  }

  .OverlaySignup {
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }
`;
const CloseButton = styled.button`
  position: absolute;
  top: 0rem;
  right: 1.3rem;
  background: none;
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 24px;
    background: #000;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;
const SignupButton = styled.button`
  color: #fff;
  background: #eb1700;;
  border: none;
  border-radius: 25px;
  width: 90%;
  height: 40px;
  font-size: 19px;
  margin-bottom: 20px;
  margin-top: 20px;

  &:hover {
    background: #fff;
    border: 1px solid #eb1700;;
    color: #eb1700;;
  }
`;

const PhoneInputNumber = styled(PhoneInput)`
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  height: 10%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
  width: 90%;
  border-radius: 25px;
  display: flex;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;
const PasswordMessage = styled.div`
  color: red;
  font-family: QuickSandLight, sans-serif;
  margin-left: 8px;
  font-size: 15px;
  float: left;
  padding-bottom: 5px;
  text-align: left;
  display:flex;
  align-items:center;
`;

const ErrorMessageIcon = styled(ErrorOutlineIcon)`
  vertical-align: middel;
  margin-right: 3px;
`;