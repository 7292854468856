import React, { useEffect, useState } from 'react'
import Modal from "react-modal";
import { setModalLogin, setModalResetPassword } from '../../shared/slice/ModalLogin/ModalLoginSlice';
import { store } from '../../shared';
import { checkClient, resetPassword } from '../../shared/slice/auth/AuthService';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { setScroll } from '../../shared/slice/scroll/ScrollSlice';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClearIcon from "@mui/icons-material/Clear";
import FirstPage from '../../screens/FirstPage';
import { eatorder } from '../../assets/images/exports';
import Form from "react-bootstrap/Form";
import { setLoggedInUser, setToken } from '../../shared/slice/auth/AuthSlice';

export default function ResetPassword() {

  const { token } = useParams()
  console.log("token",token)
  const navigate = useNavigate()
  const [userId, setUserId] = useState(null)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  useEffect(() => {
    
    const checkClientFunc = async () => {
      try {
        const check = await checkClient({ token: token });

        if (check.success === true) {
          console.log(check.userId)
          setUserId(check.userId)
        } else {
          navigate("/page404")
        }
      } catch (err) {
        navigate("/page404")
      }
    }
    checkClientFunc()
    
  }, [])



  return (
    <>

      <StyledModalLogin
        isOpen={true}
        onRequestClose={() => { navigate("/") }}
      >
        <ButtonClose onClick={() => {
          navigate("/")
        }}>
          <ClearIcon style={{ fontSize: "27px" }} />
        </ButtonClose>
        <img
          src={eatorder}
          style={{
            width: "150px",
            height: "50px",
            objectFit: "contain",
            marginBottom: "15%",
            marginLeft: "29%",
          }}
        ></img>
        <Form.Control
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            marginLeft: "5%",
            width: "90%",
            borderRadius: "25px",
          }}
          type="password"
          placeholder="Password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={(e) => e.stopPropagation()}
        />
        <Form.Control
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            marginLeft: "5%",
            width: "90%",
            borderRadius: "25px",
          }}
          type="password"
          placeholder="Confirm password"
          id="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          onKeyDown={(e) => e.stopPropagation()}
        />

        <LoginButtons onClick={async (e) => {
          try {
            e.preventDefault()
            if (password !== confirmPassword) {
              toast.error(`Please verify your password.`);
            }else if(password === confirmPassword && password === ""){
              toast.error(`Password empty.`);
            }else if (password === confirmPassword && password.length < 8){
              toast.error(`Password must be grater or equalt than 8 char.`);
            } else {
              const reset = await resetPassword({ password: password }, userId, token)
              if (reset) {
                toast.success(`${reset.message}`);
                store.dispatch(setToken({ token: token }))
                store.dispatch(setLoggedInUser({ user: reset.user }))
                navigate("/")
              }
            }
          } catch (err) {

          }

        }}>
          Save
        </LoginButtons>
      </StyledModalLogin>
    </>

  )
}

function ReactModalAdapter({ className, modalClassName, ...props }) {
  return (
    <Modal
      className={modalClassName}
      portalClassName={className}
      {...props}
    />
  )
}

const StyledModalLogin = styled(ReactModalAdapter).attrs({
  modalClassName: 'Modal',
  overlayClassName: 'Overlay'
})`
  .Modal {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      padding: 20px;
      border-radius: 15px;
      height:400px;
      width: 400px;
      text-align: center;
  }
      @media (max-width: 768px) {
          .Modal{
              width: 90%;
              min-width:90%;
          }
      }
  
  .Overlay {
      background: rgba(0, 0, 0, 0.2);
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999;
  }
`

const ButtonClose = styled.button`
  position: absolute;
  top: 5px;
  right: 3px;
  padding-right: 10px;
  cursor: pointer;
  background-color: white;
  margin-top: 2px;
`;

const LoginButtons = styled.button`
  color: #fff;
  background: #AAAAAA;
  border: none;
  border-radius: 25px;
  width: 90%;
  height: 40px;
  font-size: 19px;

//   &:hover {
//     background: #fff;
//     border: 1px solid var(--primaryColor);
//     color: var(--primaryColor);
//   }
`;