import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import StoreIcon from "@mui/icons-material/Store";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import {
  getMenuByStore,
  getModeConsomation,
  getProductByStoreByMode,
  getStoreById,
} from "../../shared/slice/restaurant/RestaurantService";
import { store } from "../../shared";
import {
  setFrais,
  setMenu,
  setMode,
  setModeId,
  setModeSelectedId,
  setProduct,
  setRestaurant,
  setRestaurantSelected,
  setTaux,
} from "../../shared/slice/restaurant/RestaurantSlice";
import { useLocation, useNavigate } from "react-router-dom";
import ModalDelivery from "../Modals/ModalDelivery";
import { setOrder } from "../../shared/slice/order/OrderSlice";
import { resetPromo } from "../../shared/slice/promos/PromosSlice";
import { getcompanybyid } from '../../shared/slice/company/CompanyService';
import { getstorebyidcompany } from '../../shared/slice/restaurant/RestaurantService';
import { lighten } from "polished";
import { setModeDelivery, setModesModal } from "../../shared/slice/ModalMode/ModalMode";
import { setStoresModal } from "../../shared/slice/ModalStores/ModalStores";
export default function StoreNavbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const restaurants = useSelector((state) => state.restaurant.restaurant);
  const restaurantSelected = useSelector(
    (state) => state.restaurant.restaurantSelected
  );
  const modes = useSelector((state) => state.restaurant.mode);
  const modeSelectedId = useSelector((state) => state.restaurant.modeSelectedId);
  const modeName = useSelector((state) => state.restaurant.modeName);
  const [Company, setCompany] = useState([]);
  const location = useLocation();
  const { delivery } = location.state || {};
  useEffect(() => {
    const fetchCompanyByName = async () => {
      try {
        const res = await getcompanybyid(restaurantSelected.companyId);
        if (res.length > 0) {
          console.log('company  ::::  ',res)
          const storeRes = await getstorebyidcompany(restaurantSelected.companyId);
          setCompany(storeRes);
          dispatch(setRestaurant({ restaurant: storeRes.stores }));
        } else {
          console.log('Page not found');
          navigate(`/page404`);
        }
      } catch (err) {
        console.error("Error fetching company by ID:", err);
      }
    };
    fetchCompanyByName();
  }, [restaurantSelected]);
  const address = restaurantSelected.address;
  const maxLength = 20;
  // const handleStoreSelectorChange = (storeId) => {
  //   navigate(`/select-store/${storeId}`)
  //   store.dispatch(setModesModal({ modesModal: true }));
  //   const fetchedStoresById = async () => {
  //     await getStoreById(storeId)
  //       .then(async (res) => {
  //         store.dispatch(setRestaurantSelected({ restaurantSelected: res }));
  //         store.dispatch(setModeSelectedId({ modeSelectedId: res.defaultMode }));
  //         document.documentElement.style.setProperty("--primaryColor", res.primairecolor);
  //         document.documentElement.style.setProperty(
  //           "--primaryColorLight",
  //           lighten("0.3", res.primairecolor)
  //         );
  //         await getMenuByStore(storeId)
  //           .then((res1) => {
  //             store.dispatch(setMenu({ menu: res1 }));
  //           })
  //           .catch((err) => {
  //             console.log("Page not found");
  //             navigate(`/page404`);
  //           });
  //         await getModeConsomation(res._id)
  //           .then((res4) => {
  //             store.dispatch(setMode({ mode: res4.consumationModes }));
  //           })
  //           .catch((err) => {
  //             console.log("Page not found");
  //             navigate(`/page404`);
  //           });
  //         await getProductByStoreByMode(storeId, res.defaultMode)
  //           .then((res3) => {
  //             store.dispatch(setProduct({ product: res3 }));
  //           })
  //           .catch((err) => {
  //             console.log("Page not found");
  //             navigate(`/page404`);
  //           });
  //       })
  //       .catch((err) => {
  //         console.log("Page not found");
  //         navigate(`/page404`);
  //       });
  //   };
  //   store.dispatch(setTaux({ taux: null }));
  //   store.dispatch(setFrais({ frais: null }));
  //   store.dispatch(setOrder({ order: [] }));
  //   store.dispatch(resetPromo());
  //   fetchedStoresById();
  //   localStorage.clear();
  // };
  // const handleModeSelectorChange = (mode) => {
  //   const isLivraisonMode =
  //     modes.find((m) => m.mode._id === mode)?.mode.name === "Delivery";
  //   if (isLivraisonMode) {
  //     store.dispatch(setModeDelivery({ modeDelivery: true }));
  //   } else {
  //     getProductByStoreByMode(restaurantSelected._id, mode)
  //       .then((res3) => {
  //         store.dispatch(setProduct({ product: res3 }));
  //         store.dispatch(setModeSelectedId({ modeSelectedId: mode }));
  //       })
  //       .catch((err) => {
  //         console.log("Page not found");
  //         navigate(`/page404`);
  //       });
  //     store.dispatch(setTaux({ taux: null }));
  //     store.dispatch(setFrais({ frais: null }));
  //     store.dispatch(setOrder({ order: [] }));
  //     store.dispatch(resetPromo());
  //     localStorage.clear();
  //   }
  //   store.dispatch(setModeId({ modeId: mode }));
  // };
  const storeSelector = (
    <>
          <ModeButton onClick={() => { {delivery===undefined && Company.stores &&Company.stores.length>1 && store.dispatch(setStoresModal({ storesModal: true })) }}}> 
        {restaurantSelected.name}
        <p style={{ fontSize: "11px" }}>
          {address.length > maxLength
            ? `${address.substring(0, maxLength)}...`
            : address}
        </p>
      </ModeButton>
      {/* <SelectorStyled
        value={restaurantSelected._id || ""}
        onChange={(e) => handleStoreSelectorChange(e.target.value)}
      >
        <option value="" disabled>
          {restaurantSelected.name}
        </option>
        {restaurants.map((store) => (
          <option key={store._id} value={store._id}>
            {store.name}
          </option>
        ))}
      </SelectorStyled> */}
      {/* <span style={{ color: "var(--primaryColor", margin: "0", padding: "0" }}>
        ▼
      </span> */}
    </>
  );
  const modeSelector = (
    <>
      <ModeButtons onClick={() => { store.dispatch(setModesModal({ modesModal: true })) }}>
        {modeName}
      </ModeButtons>
    </>
  );
  return (
    // <Container>
    <NavbarContainer>
      <NavItem>
        <NavLink>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              color: "var(--primaryColor)",
              border: "2px solid var(--primaryColor)",
              borderRadius: "20px",
              background: "var(--primaryColor)",
              color: "#fff",
              marginRight: "10px",
            }}
          >
            <StoreIcon />
            {storeSelector}
          </span>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              color: "var(--primaryColor)",
              border: "2px solid var(--primaryColor)",
              borderRadius: "18px",
              background: "var(--primaryColor)",
              color: "#fff",
            }}
          >
            <RestaurantIcon />
            {modeSelector}
          </span>
          <ModalDelivery />
        </NavLink>
      </NavItem>
    </NavbarContainer>
    // {/* </Container> */}
  );
}
const NavbarContainer = styled.div`
  display: flex;
  justify-content: center;
  position: sticky;
  top: 54px;
  z-index: 1000;
  background: #fff;
  margin-left: 15%;
  padding: 15px;
  @media (max-width: 1023px) {
    margin-left: 0;
  }
`;
const NavItem = styled.div``;
const NavLink = styled.div`
  text-decoration: none;
`;
const SelectorStyled = styled.select`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 10px;
  // border: 2px solid var(--primaryColor);
  outline: none;
  border-radius: 15px;
  background-color: #fff;
  color: var(--primaryColor);
  font-size: 13px;
  cursor: pointer;
  padding-right: 0px;
  width: 150px;
  @media (max-width: 768px) {
    font-size: 13px;
  }
`;
const ModeButton = styled.button`
padding: 4px;
border-radius: 15px;
background-color: #fff;
color: var(--primaryColor);
font-size: 14px;
cursor: pointer;
padding-right: 0px;
width: 150px;
height: 42px;
`
const ModeButtons = styled.button`
padding: 10px;
border-radius: 15px;
background-color: #fff;
color: var(--primaryColor);
font-size: 16px;
cursor: pointer;
padding-right: 0px;
width: 150px;
height: 42px;
`
const StoreNavbarStyled = styled(Navbar)`
  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`;
const StoreNavbarItem = styled(Navbar)`
  @media (max-width: 768px) {
    width: 50%;
    display: inline-block;
  }
`;