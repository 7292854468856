

import React, { useEffect } from 'react';
import { Banner } from '../../components/exports';
import { getallcompanies } from '../../shared/slice/company/CompanyService';
import { BaseURI, store } from '../../shared';
import { setCompany } from '../../shared/slice/company/CompanySlice';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import tw from 'twin.macro';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setMenu, setMode, setModeId, setModeSelectedId, setProduct, setRestaurant, setRestaurantSelected } from '../../shared/slice/restaurant/RestaurantSlice';
import { getstorebyidcompany } from '../../shared/slice/restaurant/RestaurantService';
import { setModesModal } from '../../shared/slice/ModalMode/ModalMode';
import { setOrder } from '../../shared/slice/order/OrderSlice';
import { resetPromo } from '../../shared/slice/promos/PromosSlice';
import { setScroll } from '../../shared/slice/scroll/ScrollSlice';

// const HomeContainer = styled.div``;

// const HContainer = styled.h2`
//   ${tw`text-2xl font-bold`} // Use Tailwind CSS utility classes for font weight
//   font-family: 'QuicksandBold', sans-serif;
// `;

// const HCompanyName = styled.h2`
//   ${tw`font-bold text-base`} // Use Tailwind CSS utility classes for font weight
//   font-family: 'QuicksandBold', sans-serif;
// `;

// const FilteredStoresContainer = styled.div`
//   ${tw`flex flex-wrap justify-around`}
// `;

// const StoreInfoContainer = styled.div`
//   ${tw`w-1/5 m-10 p-10 flex flex-col items-center`}
//   @media (max-width: 767px) {
//     ${tw`w-1/2 mb-[-30px]`}
//   }
// `;

// const LogoStoreImage = styled.img`
//   ${tw`w-60 h-60 object-contain mb-10`}
// `;

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const companies = useSelector((state) => state.company.company);
  useEffect(() => {
    store.dispatch(setRestaurant({ restaurant: [] }))
    store.dispatch(setRestaurantSelected({ restaurantSelected: undefined }))
    store.dispatch(setMenu({ menu: undefined }))
    store.dispatch(setProduct({ product: undefined }))
    store.dispatch(setMode({ mode: [] }))
    store.dispatch(setModeSelectedId({ modeSelectedId: undefined }))
    store.dispatch(setModeId({ modeId: undefined }))

    const fetchAllCompanies = async () => {
      try {
        await getallcompanies().then(res => {
          console.log("Compnaies :" ,res )
          store.dispatch(setCompany({ company: res }));
        })
         

      } catch (err) {
        console.error(err);
      }
    };

    fetchAllCompanies();
  }, []);

  const handleClick = async (company) => {
    if (company.stores.length > 1) {
      navigate(`/company/${company._id}`);
    } else {
      try {
        const storeRes = await getstorebyidcompany(company._id);
        
        dispatch(setRestaurant({ restaurant: storeRes.stores }));
        store.dispatch(setModesModal({ modesModal: true }));
        store.dispatch(setOrder({ order: [] }));
        // localStorage.clear();
        //Zhml
        // Object.keys(localStorage).forEach(key => {
        //   if (key.startsWith('QuantityInCart_')) {
        //     localStorage.removeItem(key);
        //   }
        // });
        store.dispatch(resetPromo());
        navigate(`/select-store/${storeRes.stores[0]._id}`);
        store.dispatch(setScroll({ scroll: 1}));
        // if (storeId !== restaurantSelected?._id) {
         

      } catch (error) {
        console.error("Error fetching store information:", error);
        // Handle error appropriately, e.g., show error message to the user
      }
    }
  };



  return (
    <>
      {/* <Banner /> */}
      <HomeContainer>
        <div>
          <HContainer>{t('Our Partners')}</HContainer>
          <FilteredStoresContainer>
            {companies
              .filter(company => company.stores.length > 0)
              .map(company => (
                <StoreInfoContainer
                  key={company._id}
                  onClick={() => handleClick(company)}
                >
                  <img style={{borderRadius:"15px", height:"11rem"}} src={company.CompanyBanner} alt="Company cover"></img>
                  <LogoNameContainer>
                  <LogoStoreImage src={`${BaseURI}/combined-uploads/${company.CompanyLogo}`} />
                  <HCompanyName>{company.name}</HCompanyName>
                  </LogoNameContainer>
                </StoreInfoContainer>
              ))} 
          </FilteredStoresContainer>
        </div>
      </HomeContainer>
    </>
  );
};

export default Home;

const HomeContainer = styled.div`
  margin: 0 auto;
  padding: 20px;
  // max-width: 1200px;
`;

const HContainer = styled.h2`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
`;

const FilteredStoresContainer = styled.div`
display:flex;
flex-wrap:wrap;
@media (max-width: 767px) {
  display:flex;
  flex-direction: column;
  }
`;
const LogoNameContainer = styled.div`
display:flex;
align-items:flex-end;
`;

const StoreInfoContainer = styled.div`
display:flex;
flex-direction:column;
// align-items:flex-end;
  width: 33%;
  // margin-bottom: 30px;
  padding: 20px;
  border-radius: 10px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 767px) {
    width: 100%;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 50%;
  }

`;

const LogoStoreImage = styled.img`
box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
background:#fff;
  height: 100px;
  width: 100px;
  object-fit: contain;
  border-radius: 50%;
  // margin: 0 auto 20px;
  display: block;
   margin-top: -50px;
  @media (max-width: 767px) {
    height: 100px;
  width: 100px;
  }
`;

const HCompanyName = styled.span`
  font-size: 2rem;
  margin-left: 0.5rem;
  text-align: center;
  color: #333;
  @media (max-width: 767px) {
    font-size:1.7rem;
    margin
  }
`;
